<!-- ############ Show Expired Cart Item Modal ################## -->
<!-- <button type="button" id="expired-cart-modal-trigger-btn" class="btn btn-primary" data-toggle="modal" data-target="#expired-cart-modal" style="display: block;"></button> -->
<button type="button" id="expired-cart-modal-trigger-btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#expired-cart-modal" style="display: none;"></button>
  
  <!-- Modal -->
  <div class="modal fade" id="expired-cart-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollabless">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">
          ...dddddd
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div>
      </div>
    </div>
  </div>
<!-- ############ End Expired Cart Item Modal  ################## -->


<!-- ############ Common Dialogue Modal ##############  -->
<button type="button" id="common_dialogue_modal_trigger_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#common_dialogue_modal" style="display: none;"></button>
  
  <!-- Modal -->
  <div class="modal fade" id="common_dialogue_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollabless">
      <div class="modal-content">
        <div class="modal-header" *ngIf="commonDialogueModalData.title !== null">
          <h5 class="modal-title" id="staticBackdropLabel">{{commonDialogueModalData.title}}</h5>
          <button type="button" class="btn-close close-common-dialogue-modal" data-bs-dismiss="modal" aria-label="Close" [ngClass]="{'hide': !commonDialogueModalData.isShowCloseTopCorner}"></button>
        </div>
        <div class="modal-body">
          <div *ngIf="commonDialogueModalData.bodyMessage !== ''" [innerHTML]="commonDialogueModalData.bodyMessage"></div>
          <div *ngIf="commonDialogueModalData.bodyWarningMessage !== ''" [innerHTML]="commonDialogueModalData.bodyWarningMessage"></div>
          <div *ngIf="commonDialogueModalData.bodyErrorMessage !== ''" [innerHTML]="commonDialogueModalData.bodyErrorMessage"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary modal-close-btn" data-bs-dismiss="modal" *ngIf="commonDialogueModalData.dismissButtonName !== ''" (click)="dismissButtonClickAction()">{{commonDialogueModalData.dismissButtonName}}</button>
          <button type="button" class="btn btn-kupon modal-close-btn" *ngIf="commonDialogueModalData.okButtonName !== ''" (click)="okButtonClickAction()">{{commonDialogueModalData.okButtonName}}</button>
        </div>
      </div>
    </div>
  </div>
<!-- ############ End Common Dialogue Modal ##########  -->




<!-- ############ Login Dialogue Modal ##############  -->
  <button type="button" id="login_dialogue_modal_trigger_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#login_dialogue_modal" style="display: none;"></button>
  
  <!-- Modal -->
  <div class="modal fade" id="login_dialogue_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollabless">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Login/Sign Up</h5>
          <button type="button" class="btn-close close-common-dialogue-modal" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>
<!-- ############ End Common Dialogue Modal ##########  -->