import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonDialogComponent } from 'src/app/common-component/common/common-dialog.component';
import { AuthComponent } from 'src/app/modules/common-modules/auth/auth.component';
import { CartService } from 'src/app/services/cart.service';
import { GetService } from 'src/app/services/get.service';
import { HelperService } from 'src/app/services/helper.service';
import { NodeApiCallService } from 'src/app/services/node-api-call.service';

@Component({
  selector: 'app-homepage-header',
  templateUrl: './homepage-header.component.html',
  styleUrls: ['./homepage-header.component.scss']
})
export class HomepageHeaderComponent implements OnInit {
  language = '';
  category: any;
  cloudinaryImageUrl = "";
  isBrowserLoad = false;
  labelText: any = {};
  
  @Input() appInfo: any;
  @Input() currentUser: any;
  searchPlaceholder = "";
  isLoggedIn = false;

  @ViewChild(CommonDialogComponent, {static: true})
  private commonDialog: CommonDialogComponent
  @ViewChild(AuthComponent, {static: true})
  private authComponent?: AuthComponent
  // private authComponent!: AuthComponent
  cartLength:any;
  constructor(
    private helper: HelperService,
    private getService: GetService,
    private _cartService:CartService,
    private nodeApi: NodeApiCallService,
  ) {
    this.commonDialog = new CommonDialogComponent(helper);
    // this.authComponent = new AuthComponent(helper, getService);
  }
  get isLogin(){
    return this.currentUser ? Object.keys(this.currentUser).length !== 0 : false;
  }
  ngOnInit(): void {
    this.getCartListsss();
    this._cartService.itemCount$.subscribe((res)=>{
      this.cartLength = res.length;
    })
  }

  openLoginComponent(){
    // this.commonDialog.openLoginDialogueModal({});
    if(this.authComponent)
      this.authComponent.openLoginModal();
  }
  openSignupComponent(){
    if(this.authComponent)
      this.authComponent.openSignupModal();
  }
  logout(): void{
    this.authComponent?.doLogout();
  }
  signInDialogueInput(event: any){
    
  }


  getCartListsss(){
    this.nodeApi.getCartData('app/store/cart').subscribe((res:any)=>{
      for(let i of res.body.data){
        this._cartService.updateItemCount(i);
      }
    })
  }

}
