// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig :{
    apiKey: "AIzaSyC8mD5X8Fk8vLykHdWmJMqgxwgTUUdYWkc",
    authDomain: "aswaag-cbd63.firebaseapp.com",
    databaseURL: "https://aswaag-cbd63.firebaseio.com",
    projectId: "aswaag-cbd63",
    storageBucket: "aswaag-cbd63.appspot.com",
    messagingSenderId: "302303582024",
    appId: "1:302303582024:web:68fc9e653f7b7ad089457d",
    measurementId: "G-1ZS4WS5E57"
  },
  FCM_WEB_PUSH_CERTIFICATE_KEY: '', // public vapid key
  NODE_X_APP_KEY: 'adsjc734i2biVc7yu6T^&BI*&(*oi././..v*&%V^&IKjkby8767tb@,.^u',
  
  




  // NODE_APP_API: 'http://localhost:9000/api/',
  NODE_APP_API_AUTH: 'https://aswaag.net/api/',
  NODE_APP_API: 'https://aswaag.net/api/',

  S3_ENDPOINT: "https://aswagbuckets3.s3.me-south-1.amazonaws.com/",
  urlForImage: "https://4deals-hamilton.s3.me-south-1.amazonaws.com/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
