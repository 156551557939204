<div>
    <app-homepage-header [currentUser]="currentUser" [appInfo]="appInfo"></app-homepage-header>
    <div class="main">
        <app-search-component [currentUser]="currentUser" [appInfo]="appInfo"></app-search-component>
        <app-banner 
        [isBrowserLoad]="authService.isBrowserLoad()"
        ></app-banner>
        <router-outlet></router-outlet>
    </div>
    <app-footer [appInfo]="appInfo"></app-footer>
</div>
