import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BannerAd } from 'src/app/model/banner-ad';
import { NodeApiData } from 'src/app/model/node-api-data.model';
import { NodeApiCallService } from 'src/app/services/node-api-call.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  //slides = ["/assets/images/banner/3.jpg", "/assets/images/banner/2.jpg", "/assets/images/banner/1.jpg", "/assets/images/banner/3.jpg", "/assets/images/banner/2.jpg", "/assets/images/banner/1.jpg"];
  slides: any = [];
  bannerAd: BannerAd[] = [];
  slideConfig = {
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    arrows: false,
    centerPadding: "8%",
    centerMode: true,
    slidesToShow: 1,
    speed: 2000,
    autoplaySpeed: 2000, 
    // cssEase:"linear",
    responsive: [{
        breakpoint: 1200,
        settings: {
            centerPadding: "4%"
        }
    }, {
        breakpoint: 992,
        settings: {
            centerPadding: "0",
            centerMode: false
        }
    }, {
        breakpoint: 767,
        settings: {
            centerPadding: "0%",
            centerMode: false
        }
    }, {
        breakpoint: 500,
        settings: {
            centerPadding: "0%",
            centerMode: false
        }
    }
    ]
  };

  @Input() isBrowserLoad: boolean = false;

  s3Url = environment.S3_ENDPOINT;

  constructor(
    private nodeApi: NodeApiCallService
  ) { }

  ngOnInit(): void {
    if(this.isBrowserLoad){
      let localStorageBannerAd = localStorage.getItem('_bannerad') || null;
      if(localStorageBannerAd == null || localStorageBannerAd == ""){
        this.getBannerAdObserver();
      }else{
        let ad = JSON.parse(localStorageBannerAd);
        if(ad.expiryTime < (new Date().getTime() / 1000)){
          this.getBannerAdObserver();
        }else{
          this.bannerAd = ad.ad;
        }
      }
    }
  }
  getBannerAdObserver(){
    this.getBannerAd().subscribe(data => {
      this.bannerAd = data.data;
      localStorage.setItem('_bannerad', JSON.stringify({
        ad: this.bannerAd,
        expiryTime: (new Date().getTime() / 1000) + 3600
      }));
    })
  }
  addSlide() {
    this.slides.push("/assets/images/banner/1.jpg")
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e: any) {
    // console.log('slick initialized');
  }
  
  breakpoint(e: any) {
    // console.log('breakpoint');
  }
  
  afterChange(e: any) {
    // console.log('afterChange');
  }
  
  beforeChange(e: any) {
    // console.log('beforeChange');
  }
  
  getBannerAd(): Observable<NodeApiData<BannerAd[]>> {
    return this.nodeApi.commonGetApiWithoutAuth('app/banner/ad');
  }
}
