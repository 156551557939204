import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class NodeApiCallService {

  constructor(
    private http: HttpClient,
    private cookies:CookieService
  ) { }

  commonGetApiWithAuth(apiName: string, body:any = {}, queryParam:any = {}){
    return new Observable<any>(observer => {
      let headersRequest = new HttpHeaders({
        'No-Auth': 'false',        
      })
      let url = environment.NODE_APP_API+apiName;
      if(body){
        url += '/'+ Object.keys(body).map(key => body[key]).join('/'); 
      }
      if(queryParam)
        url += '?'+ Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&'); 

      this.http.get(url, {headers: headersRequest, observe: 'response'})
        .subscribe((snapshot: HttpResponse<any>) => {
          // console.log(snapshot.body)
          // console.log(snapshot.headers);
            snapshot.body.data.headers = snapshot.headers;
            observer.next(snapshot.body.data);
            observer.complete();
        },(error) => {
          observer.error(error)
          observer.complete();
        })
    })
  }
  commonGetApiWithoutAuth(apiName: string, body:any = {}, queryParam:any = {}){
    return new Observable<any>(observer => {
      let headersRequest = new HttpHeaders({
        'No-Auth': 'true',        
      })
      let url = environment.NODE_APP_API+apiName;
      if(body){
        url += '/'+ Object.keys(body).map(key => body[key]).join('/'); 
      }
      if(queryParam)
        url += '?'+ Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&'); 

      this.http.get(url, {headers: headersRequest, observe: 'response'})
        .subscribe((snapshot: HttpResponse<any>) => {
          // console.log(snapshot)
          // console.log(snapshot.headers);
            snapshot.body.data.headers = snapshot.headers;
            observer.next(snapshot.body.data);
            observer.complete();
        },(error) => {
          observer.error(error)
          observer.complete();
        })
    })
  }
  
  commonGetPOSTApiWithoutAuth(apiName: string, body:any = {}){
    return new Observable<any>(observer => {
      let headersRequest = new HttpHeaders({
        'No-Auth': 'false',        
      })
      let url = environment.NODE_APP_API+apiName;
      

      return this.http.post(url, body, {headers: headersRequest,  observe: 'response'})
        .subscribe((snapshot: HttpResponse<any>) => {
          // console.log(snapshot)
          // console.log(snapshot.headers);
            snapshot.body.data.headers = snapshot.headers;
            observer.next(snapshot.body.data);
            observer.complete();
        },(error) => {
          observer.error(error)
          observer.complete();
        })
    })
  }

  commonPostCallWithAuth(postData: object, url: string){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    })
    return this.http.post(environment.NODE_APP_API+url, postData, {headers: headersRequest});  
  }

  postThamnal(url: string,image: any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
      // 'Content-Type':'application/octet-stream'
    })
    return this.http.post(environment.NODE_APP_API + url, image, {headers: headersRequest});  
  }

  postPhotoOrVideo(url: string,image: any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
      // 'Content-Type':'application/octet-stream'
    })
    return this.http.post(environment.NODE_APP_API + url, image, {headers: headersRequest});  
  }
  commonPostCallWithoutAuth(postData: object, url: string){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'true',
    })
    return this.http.post(environment.NODE_APP_API_AUTH+url, postData, {headers: headersRequest});  
  }
  commonPutCallWithAuth(postData: object, url: string){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    })
    return this.http.put(environment.NODE_APP_API+url, postData, {headers: headersRequest});  
  }
  commonPutCallWithoutAuth(postData: object, url: string){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'true',
    })
    return this.http.put(environment.NODE_APP_API+url, postData, {headers: headersRequest});  
  }
  commonDeleteCallWithAuth(postData: object, url: string){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    })
    return this.http.request('delete', environment.NODE_APP_API+url, {headers: headersRequest, body: postData});  
  }
  commonDeleteCallWithoutAuth(postData: object, url: string){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'true',
    })
    return this.http.request('delete', environment.NODE_APP_API+url, {headers: headersRequest, body: postData});
  }

  otpVerifyfication(otp:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-lang':'1',
      'device-type':'1',
      'x-app-key':'adsjc73u327huj4i2biVc7yu6dsjhfjT^&BI*&(*oi././..v*&%V^&I@#Kjkby8767tb@,.^u',
      'x-auth-token': this.cookies.get('_arai')
    });
    return this.http.post(`${environment.NODE_APP_API}/auth/verify-otp`,{OTP:otp},{ headers: headers })
  }


  updatePost(apiName:any,data:any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    });
    let url = environment.NODE_APP_API + apiName;
    return this.http.put(url,data,{ headers: headersRequest, observe: 'response' });
  }


  getCartData(apiName:any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    });
    let url = environment.NODE_APP_API + apiName;
    return this.http.get(url,{ headers: headersRequest, observe: 'response' });
  }

  removeCart(apiName:any){
    
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    });
    let url = environment.NODE_APP_API + apiName;
    return this.http.delete(url,{ headers: headersRequest, observe: 'response' });
  }

  rating(apiName:any,data:any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    });
    let url = environment.NODE_APP_API + apiName;
    return this.http.post(url,data,{ headers: headersRequest, observe: 'response' });
  }
  getRating(apiName:any){
    let headersRequest = new HttpHeaders({
      'No-Auth': 'false',
    });
    let url = environment.NODE_APP_API + apiName;
    return this.http.get(url,{ headers: headersRequest, observe: 'response' });
  }
}
