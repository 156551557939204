<!--Login Modal-->
<button type="button" style="display: none;" class="mt-0 mb-0 m-2 btn btn-success" id="login_trigger_btn" data-bs-toggle="modal" data-bs-target="#login">Login</button>

<div class="modal login-modal" id="login">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 login-bg">
                            <img src="/assets/images/login-icon.png" />
                        </div>
                        <div class="col-md-8">
                            <div class="login-box">
                                <div class="login-header">
                                    <div class="login-title">Login/Sign Up On 4Deals</div>
                                    <div class="loing-desc">Please provide your Mobile Number or Email to Login/Sign Up on 4Deals</div>
                                    <a href="#" data-bs-dismiss="modal" id="close-login-modal-btn"><img class="close-modal" src="/assets/images/close-modal.png" /></a>
                                    <div *ngIf="errorMessage.loginError !== ''" class="error-text">{{errorMessage.loginError}}</div>
                                </div>
                                <div class="login-body mt-3">
                                    <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
                                        <div class="mb-3">
                                            <input type="text" formControlName="userName" class="form-control" placeholder="Enter username / Email" [ngClass]="{ 'is-invalid': isLoginFormSubmit && loginFormControl.userName.errors }" />
                                            <div *ngIf="isLoginFormSubmit && loginFormControl.userName.errors" class="invalid-feedback">
                                                <div *ngIf="loginFormControl.userName.errors.required">Username or email is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <input type="password" formControlName="password" class="form-control" placeholder="Enter password" [ngClass]="{ 'is-invalid': isLoginFormSubmit && loginFormControl.password.errors }" />
                                            <div *ngIf="isLoginFormSubmit && loginFormControl.password.errors" class="invalid-feedback">
                                                <div *ngIf="loginFormControl.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <button class="btn-login w-100" type="submit" *ngIf="!isLoginFormProcessing">Continue</button>
                                            <button class="btn-login w-100 loader-btn" type="button" disabled *ngIf="isLoginFormProcessing">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp; Wait... &nbsp;
                                            </button>
                                        </div>
                                        <div class="mb-3 text-center login-tc">By Submitting in you agree to <a href="#">T&C</a> and <a href="#">Privacy Policy</a></div>
                                        <div class="mb-3 text-center login-tc"><a href="javascript:void(0)" (click)="openForgotPasswordModal()">Forgot Password ?</a></div>
                                    </form>
                                </div>
                                <!-- <div class="login-footer mt-5">
                                    <h4 class="login-footer-title">Login Using</h4>
                                    <div class="login-social-icons mt-3">
                                        <div class="login-social-box">
                                            <img src="/assets/images/icon-google.png" />
                                            <span>Google</span>
                                        </div>
                                        <div class="login-social-box">
                                            <img src="/assets/images/icon-facebook.png" />
                                            <span>Facebook</span>
                                        </div>
                                        <div class="login-social-box">
                                            <img src="/assets/images/icon-apple.png" />
                                            <span>Apple</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--/ Login Modal-->
<!--Register Modal-->
<button type="button" style="display: none;" class="mt-0 mb-0 m-2 btn btn-success" id="signup_trigger_btn" data-bs-toggle="modal" data-bs-target="#register">Signup</button>
<div class="modal login-modal" id="register">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 login-bg">
                            <img src="/assets/images/register-icon.png" />
                        </div>
                        <div class="col-md-8">
                            <div class="login-box">
                                <div class="login-header">
                                    <div class="login-title">
                                        <img src="/assets/images/icon-back.png" class="me-2">Get Registered</div>
                                    <div class="loing-desc">Please provide your Mobile Number or Email to Login/Sign Up on 4Deals</div>
                                    <a href="#" data-bs-dismiss="modal" class="close-signup-modal"><img class="close-modal" src="/assets/images/close-modal.png" /></a>
                                    <div *ngIf="errorMessage.signupError !== ''" class="error-text">{{errorMessage.signupError}}</div>
                                </div>
                                <div class="login-body mt-3">
                                    <form [formGroup]="signupForm" (ngSubmit)="doSignup()">
                                        <div class="mb-3">
                                            <input type="text" formControlName="name" class="form-control" placeholder="Name" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.name.errors }" />
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.name.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.name.errors.required">Name is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <input type="text" formControlName="userName" class="form-control" placeholder="User Name" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.userName.errors }" />
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.userName.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.userName.errors.required">Username is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <input type="text" formControlName="email" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.email.errors }" />
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.email.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.email.errors.required">Email is required</div>
                                                <div *ngIf="signupFormControl.email.errors.email">Email is invalid</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <input type="password" formControlName="password" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.password.errors }"  />
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.password.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <input type="password" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.confirmPassword.errors }"  />
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.confirmPassword.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.confirmPassword.errors.required">Password is required</div>
                                                <div *ngIf="signupFormControl.confirmPassword.errors.matching">Password does not match</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <input type="text" formControlName="mobile" class="form-control" placeholder="Mobile" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.mobile.errors }" />
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.mobile.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.mobile.errors.required">Mobile is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <select class="form-control" formControlName="country" [ngClass]="{ 'is-invalid': isSignupFormSubmit && signupFormControl.country.errors }">
                                                <option value="">-Select Country-</option>
                                                <option value="{{country.iso2}}" *ngFor="let country of countryList">{{country.name}}</option>
                                            </select>
                                            <div *ngIf="isSignupFormSubmit && signupFormControl.country.errors" class="invalid-feedback">
                                                <div *ngIf="signupFormControl.country.errors.required">Country is required</div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <button class="btn-login w-100" type="submit" [disabled]="isSignupFormDisable">Continue</button>
                                            <button class="btn loader-btn" type="button" disabled *ngIf="isSignupFormDisable">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp; Wait... &nbsp;
                                            </button>
                                        </div>
                                        <div class="mb-3 text-center login-tc">By Submitting in you agree to <a href="#">T&C</a> and <a href="#">Privacy Policy</a></div>
                                    </form>
                                </div>
                                <!-- <div class="login-footer mt-5">
                                    <h4 class="login-footer-title">Login Using</h4>
                                    <div class="login-social-icons mt-3">
                                        <div class="login-social-box">
                                            <img src="/assets/images/icon-google.png" />
                                            <span>Google</span>
                                        </div>
                                        <div class="login-social-box">
                                            <img src="/assets/images/icon-facebook.png" />
                                            <span>Facebook</span>
                                        </div>
                                        <div class="login-social-box">
                                            <img src="/assets/images/icon-apple.png" />
                                            <span>Apple</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--/ Register Modal-->
<!--Forgot Password Modal-->
<button type="button" style="display: none;" class="mt-0 mb-0 m-2 btn btn-success" id="forgot_password_trigger_btn" data-bs-toggle="modal" data-bs-target="#forgot-password">Signup</button>
<div class="modal login-modal" id="forgot-password">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 login-bg">
                            <img src="/assets/images/register-icon.png" />
                        </div>
                        <div class="col-md-8">
                            <div class="login-box">
                                <div class="login-header">
                                    <div class="login-title">
                                        <img src="/assets/images/icon-back.png" class="me-2">Forgot Password</div>
                                    <div class="loing-desc">Please provide your username or email to get new password on your email</div>
                                    <a href="#" data-bs-dismiss="modal" id="close-forgot-password-modal-btn"><img class="close-modal" src="/assets/images/close-modal.png" /></a>
                                    <div *ngIf="errorMessage.forgotPasswordError !== ''" class="error-text">{{errorMessage.forgotPasswordError}}</div>
                                    <div *ngIf="isPasswordSentToEmail" class="error-text">Your password has successfully sent to your registered email</div>
                                </div>
                                <div class="login-body mt-3">
                                    <form [formGroup]="forgotPasswordForm" (ngSubmit)="doForgotPassword()">
                                        <div class="mb-3">
                                            <input type="text" class="form-control" formControlName="userName" placeholder="Enter username or email" [ngClass]="{'is-invalid': isForgotPasswordFormSubmit && forgotPasswordFormControl.userName.errors}" />
                                            <div *ngIf="isForgotPasswordFormSubmit && forgotPasswordFormControl.userName.errors" class="invalid-feedback">
                                                <div *ngIf="isForgotPasswordFormSubmit && forgotPasswordFormControl.userName.errors.required">Username or email is required</div>
                                            </div>
                                        </div>
                                        <!--<div class="mb-3">
                                            <input type="password" class="form-control" placeholder="New Password" />
                                        </div>
                                        <div class="mb-3">
                                            <input type="password" class="form-control" placeholder="Confirm Password" />
                                        </div>-->
                                        <div class="mb-3">
                                            <button class="btn-login w-100" type="submit" *ngIf="!isForgotPasswordFormProcessing">Send Password</button>
                                            <button class="btn-login w-100 loader-btn" type="button" disabled *ngIf="isForgotPasswordFormProcessing">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp; Wait... &nbsp;
                                            </button>
                                        </div>
                                        <!--<div class="mb-3 text-center login-tc">By Submitting in you agree to <a href="#">T&C</a> and <a href="#">Privacy Policy</a></div>-->
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--/ Forgot Password Modal-->

<!--OTP Modal-->
<!-- <div id="recaptcha-container" class="justify-center flex"></div> 
<button type="button" style="display: none;" class="mt-0 mb-0 m-2 btn btn-success" id="otp_trigger_btn" data-bs-toggle="modal" data-bs-target="#otp-modal">OTP</button>
<div class="modal login-modal" id="otp-modal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4 login-bg">
                            <img src="/assets/images/otp-icon.png" />
                        </div>
                        <div class="col-md-8">
                            <div class="login-box">
                                <div class="login-header">
                                    <div class="login-title">
                                        <img src="/assets/images/icon-back.png" class="me-2">OTP Verification</div>
                                </div>
                                <div class="login-body mt-3 text-center">
                                    <div>
                                        <img src="/assets/images/icon-password.png" />
                                    </div>
                                    <div class="otp-verify-box text-center mb-4">
                                        <div>Please enter verification code (OTP) sent to: {{signupFormControl.mobile.value}}</div>
                                        <div class="otp-box-container mt-3">
                                            <ng-otp-input *ngIf="isPlatformBrowser" (onInputChange)="onOtpChange($event)"  [config]="otpConfig"></ng-otp-input>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <span *ngIf="errorMessage.otpError !== ''" class="error-text">{{errorMessage.otpError}}</span>
                                        <button class="btn-login w-100" (click)="verifyOTP()" *ngIf="!isOTPVerificationProcessing">Verify OTP</button>
                                        <button class="btn-login w-100 loader-btn" type="button" disabled *ngIf="isOTPVerificationProcessing">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Wait... &nbsp;
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--/ OTP Modal-->