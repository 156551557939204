<footer>
    <div class="dwnld_app">
        <div class="applink">
            <ul class="list-inline ">
                <li class="list-inline-item">
                    <a href="#">
                        <img src="/assets/images/icons/playstore.png" alt="">
                    </a>
                </li>
                <li class="list-inline-item">
                    <a href="#">
                        <img src="/assets/images/icons/appstore.png"
                            alt="">
                    </a>
                </li>
            </ul>
            <h2>Download the 4Deals For Free</h2>
            <p>Download the app on your phone by entering your phone number below </p>
            <div class="mb-3 input-group">
                <input placeholder="Enter your phone number" aria-label="Enter your phone number" aria-describedby="basic-addon2"
                    class="form-control">
                <button id="button-addon2" type="button" class="btn btn-success">Submit</button>
            </div>
        </div>
        <img src="/assets/images/backgrounds/footer.png" class="full-width footbg" alt="">
    </div>
    <div class="footer-last">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <img src="/assets/images/logo.svg"
                        class="logo-foot" alt="">
                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots inContrary to popular
                        belief, Lorem Ipsum is not simply</p>
                    <ul class="list-inline social-links">
                        <li class="list-inline-item">
                            <a href="#">
                                <img src="/assets/images/icons/fb.svg" alt="">
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#">
                                <img src="/assets/images/icons/google.svg" alt="">
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#">
                                <img src="/assets/images/icons/apple.svg" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-8">
                    <div class="row footer_links">
                        <div class="col-md-4">
                            <h3>Company</h3>
                            <a href="/about-us">About Us</a>
                            <a href="/contact-us">Contact Us</a>
                            <a href="/special" target="_blank">Special</a>
                            <a href="">Press</a>
                        </div>
                        <div class="col-md-4">
                            <h3>Products</h3>
                            <a href="">Features</a>
                            <a href="">Pricing</a>
                            <a href="">News</a>
                            <a href="">Help desk</a>
                            <a href="">Support</a>
                        </div>
                        <div class="col-md-4">
                            <h3>Legal</h3>
                            <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                            <a href="/terms-conditions" target="_blank">Terms and Conditions</a>
                            <a href="/return-policy" target="_blank">Return Policy</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 copyryt">
                    <a href="#" class="pull-right">Back to Top
                        <img src="/assets/images/icons/to-top.svg" alt="">
                    </a>
                    <p class="pull-left mb-0">Copyright 2021.All Rights Reserved by us!</p>
                </div>
            </div>
        </div>
    </div>
</footer>