import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import decodeToken from 'jwt-decode';
import { AppInfo } from '../model/app-info.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  newImage = [];
  private isBrowser: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
  ) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }
//   getAppMetaData(): AppInfo {
//     let language: string = localStorage.getItem('_ln') || "EN";
//     try{
//       let info = {
//         language: language
//       };
//       return info;      

//     }catch(error){
//       return {
//           language: 'EN'
//       };
//     }
//   }
  isPlatformBrowser(): boolean {
    return this.isBrowser
  }
  decodeToken(token: string): object{
      let decodedToken: object = decodeToken(token);
      // const expirationDate = helper.getTokenExpirationDate(token);
      // const isExpired = helper.isTokenExpired(token);
      return decodedToken;
  }
  isObjectEmpty(obj: object){
    return Object.entries(obj).length > 0 ? false : true;
  }
  htmlElement(selector: string){
      let htmlElement: HTMLElement = document.querySelector(selector) as HTMLElement;
      return htmlElement;
  }
  showImagePreview(files: FileList, previewElement: string){
      if (files.length > 0) {
          for(let i = 0; i < files.length; i++){
              var reader = new FileReader();
              reader.readAsDataURL(files[i]);            
              reader.onload = function(e) {
                  let htmlElement: any = document.querySelector(previewElement);
                  // console.log(htmlElement);
                  htmlElement.src = reader.result;
              }
          }
      }
  }
  showSingleImagePreview(file: File, previewElement: string){
              var reader = new FileReader();
              reader.readAsDataURL(file);            
              reader.onload = function(e) {
                  let htmlElement: any = document.querySelector(previewElement);
                  // console.log(htmlElement);
                  htmlElement.src = reader.result;
              }
      
  }
  // createImage(files: FileList){
  //     if (files && files.length) {
  //         for (let i = 0; i < files.length; i++) {
  //           const file = files[i];
  //           this.newImage.push(file);
  //           const reader = new FileReader();
  //           reader.readAsDataURL(file);
  //           reader.onload = () => {
  //             const img = new Image();
  //             img.src = reader.result as string;
  //             img.onload = () => {
  //               this.newImage[i].width = img.naturalWidth;
  //               this.newImage[i].height = img.naturalHeight;
  //             };
  //           };
  //         }
  //     }
  // }
  // dataURLtoFile(dataurl: string, filename: string) {

  //     var arr = dataurl.split(','),
  //         mime = arr[0].match(/:(.*?);/)[1],
  //         bstr = atob(arr[1]), 
  //         n = bstr.length, 
  //         u8arr = new Uint8Array(n);
          
  //     while(n--){
  //         u8arr[n] = bstr.charCodeAt(n);
  //     }
      
  //     return new File([u8arr], filename, {type:mime});
  // }
  discountPercentage(discountPrice: number, price: number) {
      let discount = price - discountPrice;
      let percentage = (discount*100)/price;
      return percentage;
  }
  discountPrice(price: number, discountPrice: number) {
      let discount = discountPrice/100;
      discount = price - (discountPrice * price);
      return discount.toFixed(3);
  }
  convertEpochToSpecificTimezone(offset: number){
      var d = new Date(1495159447834);
      var utc = d.getTime() + (d.getTimezoneOffset() * 60000);  //This converts to UTC 00:00
      var nd = new Date(utc + (3600000*offset));
      return nd.toLocaleString();
  }
  epochToDateTime(timestamp: any, format=''){
      // console.log(timestamp)
      if(timestamp != undefined && timestamp != "undefined"){
          var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          // console.log(timestamp.length);
          timestamp = parseInt(timestamp);
          if(timestamp.length === "undefined" || timestamp.length === undefined){
              // console.log('if', timestamp.toString().length)
              timestamp = (timestamp.toString().length > 10) ? timestamp : timestamp*1000;
              // console.log(timestamp);
          }
          let date = new Date(timestamp);
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          let minute = (minutes < 10 ? '0'+minutes : minutes);
          var strTime = hours + ':' + minute + ' ' + ampm;
          if(format){
              if(format == "m-d-Y")
                  return (date.getMonth()+1) + '-' +date.getDate() + '-'+ date.getFullYear() +' '+ strTime;
              if(format == "d-m-Y")
                  return date.getDate() + '-'+(date.getMonth()+1) +'-'+ date.getFullYear() +' '+ strTime;
              if(format == "m/d/Y")
                  return (date.getMonth()+1) + '/' +date.getDate() + '/'+ date.getFullYear() +' '+ strTime;
              if(format == "d/m/Y")
                  return date.getDate() + '/'+(date.getMonth()+1) +'/'+ date.getFullYear() +' '+ strTime;
              else
                  return date.getDate() + '/'+(date.getMonth()+1) +'/'+ date.getFullYear() +' '+ strTime;

          }
          else
              return date.getDate()+' '+months_arr[date.getMonth()] + ', '+ date.getFullYear() +' '+ strTime;
      }else{
          return '';
      }
  }
  epochToDate(timestamp: any, format=''){
      // console.log(timestamp)
      if(timestamp != undefined && timestamp != "undefined" && timestamp != 0){
          var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          // console.log(timestamp.length);
          timestamp = parseInt(timestamp);
          if(timestamp.length === "undefined" || timestamp.length === undefined){
              // console.log('if', timestamp.toString().length)
              timestamp = (timestamp.toString().length > 10) ? timestamp : timestamp*1000;
              // console.log(timestamp);
          }
          let date = new Date(timestamp);
          if(format){
              if(format == "m-d-Y")
                  return (date.getMonth()+1) + '-' +date.getDate() + '-'+ date.getFullYear();
              if(format == "d-m-Y")
                  return date.getDate() + '-' +(date.getMonth()+1) + '-'+ date.getFullYear();
              if(format == "m/d/Y")
                  return (date.getMonth()+1) + '/' +date.getDate() + '/'+ date.getFullYear();
              if(format == "d/m/Y")
                  return date.getDate() + '/' +(date.getMonth()+1) + '/'+ date.getFullYear();
              else
                  return date.getDate() + '/' +(date.getMonth()+1) + '/'+ date.getFullYear();
          }
          else
              return date.getDate()+' '+months_arr[date.getMonth()] + ', '+ date.getFullYear();
      }else{
          return '';
      }
  }
  epochToMonth(timestamp: any, monthWithYear = false){
      // console.log(timestamp)
      if(timestamp != undefined && timestamp != "undefined" && timestamp != 0){
          var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
          // console.log(timestamp.length);
          timestamp = parseInt(timestamp);
          if(timestamp.length === "undefined" || timestamp.length === undefined){
              // console.log('if', timestamp.toString().length)
              timestamp = (timestamp.toString().length > 10) ? timestamp : timestamp*1000;
              // console.log(timestamp);
          }
          let date = new Date(timestamp);
          if(monthWithYear){
              return months_arr[date.getMonth()] + ' ' + date.getFullYear();    
          }
          return months_arr[date.getMonth()];
      }else{
          return '';
      }
  }
  matCalendarDateFormat(date: any, format = 'm-d-Y'){
      if(date){
          let timestamp = new Date(date).getTime();
          let newDate = this.epochToDate(timestamp, format);
          // console.log(newDate);
          return newDate;
      }
      return '';
  }
  formatDate(date: any, format = ""){
      var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var dd = date.getDate();
      var mm = date.getMonth()+1;
      var yyyy = date.getFullYear();
      // if(dd<10) {dd='0'+dd}
      // if(mm<10) {mm='0'+mm}
      if(format == "")
          date = dd+' '+months_arr[mm-1]+', '+yyyy;
      else if(format == "m-d-Y")
          date = mm+'/'+dd+'/'+yyyy;  
      
      return date
   }
  Last7Days() {
      var result = [];
      for (var i=7; i>=0; i--) {
          var d = new Date();
          d.setDate(d.getDate() - i);
          result.push( this.formatDate(d) )
      }   
      return result; 
      // return(result.join(','));
  }
  last30Days(){
      var result = [];
      for (var i=30; i>=0; i--) {
          var d = new Date();
          d.setDate(d.getDate() - i);
          result.push( this.formatDate(d) )
      }   
      return result; 
  }
  last12Months(withYear = true, shortMonth = true){
      var fullMonthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
      var shortMonthsName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var d = new Date();
      d.setDate(1);
      let result = [];
      for (let i=11; i>=0; i--) {
          d.setMonth(d.getMonth() - 1);
          // console.log(shortMonthsName[d.getMonth()] + ' ' + d.getFullYear());
          result[i] = shortMonthsName[d.getMonth()] + ' ' + d.getFullYear();
      }
      return result;
  }
  orderStatus(status: number, isSelectedDelivery: boolean){
      if(status == 0 || status == 1)
          return "RECEIVED";
      if(status == 2)
          return "PROCESSING";
      if(status == 3 && isSelectedDelivery)
          return "DISPATCHED";
      if(status == 3 && !isSelectedDelivery)
          return "DELIVERED";
      if(status == 4)
          return "DELIVERED";
      else
        return "";
  }
  encodeURL(str: string){
      return encodeURIComponent(str);
  }
  decodeURL(str: string){
      return decodeURIComponent(str);
  }
  strReplace(str: string, replace: string , replaceBy: string){
      return str.replace(replace, replaceBy);
  }
  arrayColumn(array: any, columnName: string){
    return array.map(function(value: any) {
        return value[columnName];
    });
  }
  combineWhiteSpaceWords(str: string){
      if(str)
        return str.replace(/ +/g, '');
    
    return "";
  }
  createSEOString(str: string): string{
      if(str){
          str = str.toLowerCase();
        //   str = str.replaceAll('-', ''); // remove - if string already have - character
        //   str = str.replaceAll(' ', '-'); // remove double space with single space
        str = str.split(" ").join('-');
        //   console.log(str);
        str = str.replace(/[^a-zA-Z0-9]/g, '-');
        //   console.log(str)
        // return str.replaceAll('---', '-');
        str = str.split("---").join('-');
        return str.split("--").join('-');
      }
      return "";
  }

  singleImage(image: string){
    let imageArray = image.split(',');
    if(imageArray.length > 0){
        return imageArray[0];
    }else{
        return "";
    }
  }
  offerTimeDifference(promotionalStartTime: number, currentTime: number): string{
    let difference = promotionalStartTime - currentTime;
    let diffDay = Math.floor(difference / (3600*24));
    difference -= diffDay * 3600 *24;
    let diffHour = Math.floor(difference / 3600);
    difference -= diffHour * 3600;
    let diffMinute = Math.floor((difference) / 60);
    difference -= diffMinute * 60;
    let diffSeconds = Math.floor(difference);  
    // this.promotionalOfferStartTimer = (diffDay.toString()) + ' Days ' + (diffHour.toString()) + 'h: ' + (diffMinute.toString()) + 'm: ' + (diffSeconds.toString()) + 's';
    let diff = "";
    if(diffDay > 0){
      diff += (diffDay.toString()) + ' Days ';
    }
    if(diffHour > 0){
      diff += (diffHour.toString()) + 'h: ';
    }
    diff += (diffMinute.toString()) + 'm: ' + (diffSeconds.toString()) + 's';
    return  diff;
   
    // console.log(this.promotionalOfferStartTimer)
 
}
  // exportData(data: any, fileName: string, sheetName = ''){
  //     // console.log(data);
  //     fileName = fileName +'-' + new Date().toISOString();
  //     const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  //     /* generate workbook and add the worksheet */
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, sheetName);

  //     /* save to file */
  //     XLSX.writeFile(wb, fileName+'.xlsx');
  // }
  pickupStatus(status: number){
      if(status == 1){
          return "PENDING";
      }else if(status == 2){
          return "PICKED UP";
      }else if(status == 3){
          return "OUT OF STOCK";
      }else if(status == 4){
          return "PICKUP LATER";
      }else if(status == 5){
          return "FROM OUTSIDE";
      }else if(status == 6){
          return "OTHER";
      }else{
        return ""
      }
  }

  strMultipleReplace(str: string, mapObj: any){
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi");        
        str = str.replace(re, function(matched){
            // return mapObj[matched.toLowerCase()];
            return mapObj[matched];
        });       
        return str;
  }
  strReplaceAll(str: string, replacedBy: string, replaceTo: string){
      if(str !== "")
        return str.split(replaceTo).join(replacedBy);
      return str;
  }


}
