import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TransferHttpCacheModule} from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shop-layout/header/header.component';
import { LayoutComponent } from './shop-layout/layout/layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderRequestInterceptor } from './services/_interceptors/header-request.interceptor';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ToastrModule } from 'ngx-toastr';
import { CookieModule } from 'ngx-cookie';
import { ErrorInterceptor } from './services/_interceptors/error.interceptor';

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterMenuComponent } from './shop-layout/footer/footer-menu/footer-menu.component';
import { SharedModule } from './shared.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FooterComponent } from './shop-layout/footer/footer.component';
import { HomepageHeaderComponent } from './shop-layout/header/homepage-header/homepage-header.component';
import { BannerComponent } from './shop-layout/layout/banner/banner.component';



@NgModule({
  declarations: [
    AppComponent,
    // BannerComponent,
    // HomepageHeaderComponent,
    // HomePageLayoutComponent,
    // SearchComponentComponent,
    // HeaderComponent,
    // FooterComponent,
    // LayoutComponent,
    // PaymentLayoutComponent,
    // PageNotFoundComponent,
    // CategoryNavComponent,
    // PaymentHeaderComponent,
    // CategoryListingLayoutComponent,
    // FooterMenuComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    HttpClientModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    SocialLoginModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireDatabaseModule,
    AngularFirestoreModule,
    // AngularFireMessagingModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderRequestInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("")
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("")
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
