<!-- Sticky Footer -->
<!-- <div class="sticky-footer sticky-content fix-bottom"> -->
    <div class="footer-menu only-mobile">
        <!-- <div class="fix-bottom"> -->
            <a routerLink="/{{appInfo.urlFirstSegment}}/" routerLinkActive="kupon-color" class="sticky-link active menu">
                <i class="d-icon-home"></i>
                <span>Home</span>
            </a>
            <a routerLink="/{{appInfo.urlFirstSegment}}/mv/category" routerLinkActive="kupon-color" class="sticky-link menu">
                <!-- <i class="d-icon-volume"></i> -->
                <i class="fa fa-th-large" aria-hidden="true"></i>
                <span>Categories</span>
            </a>
            <a routerLink="/{{appInfo.urlFirstSegment}}/wishlist" routerLinkActive="kupon-color" class="sticky-link menu">
                <i class="d-icon-heart"></i>
                <span>Wishlist</span>
            </a>
            <a routerLink="/{{appInfo.urlFirstSegment}}/mv/settings-menu" routerLinkActive="kupon-color" class="sticky-link menu">
                <i class="d-icon-user"></i>
                <span>Account</span>
            </a>
            <!-- <div class="header-search hs-toggle dir-up">
                <a href="#" class="search-toggle sticky-link">
                    <i class="d-icon-search"></i>
                    <span>Search</span>
                </a>
                <form action="#" class="input-wrapper">
                    <input type="text" class="form-control" name="search" autocomplete="off"
                        placeholder="Search your keyword..." required />
                    <button class="btn btn-search" type="submit">
                        <i class="d-icon-search"></i>
                    </button>
                </form>
            </div> -->
        </div>