import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LayoutComponent } from './shop-layout/layout/layout.component';

const routes: Routes = [
  

  {
    path: 'ar',
    children: [
      { path: '', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule) },  
      { path: 'post', loadChildren: () => import('./modules/ad-create/ad-create.module').then(m => m.AdCreateModule) },
      { path: 'stores', loadChildren: () => import('./modules/stores/stores.module').then(m => m.StoresModule) },
      { path: 'ad/details', loadChildren: () => import('./modules/ad-details/ad-details.module').then(m => m.AdDetailsModule) },
      { path: 'subscription', loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule) },
      { path: 'receipt', loadChildren: () => import('./modules/receipt/receipt.module').then(m => m.ReceiptModule) },
      { path: 'my-account', loadChildren: ()=> import('./modules/my-account/my-account.module').then(m=>m.MyAccountModule) },
      { path: 'success-pay', loadChildren: ()=> import('./modules/subscription-confirm/subscription-confirm.module').then(m=>m.SubscriptionConfirmModule) },
      { path: 'payment-fail', loadChildren: ()=> import('./modules/subscription-failed/subscription-failed.module').then(m=>m.SubscriptionFailedModule) },
      { path: 'subscription-confirmation', loadChildren: ()=> import('./modules/subscription-confirmation/subscription-confirmation.module').then(m=>m.SubscriptionConfirmationModule) },
      { path: 'wish-list', loadChildren: ()=> import('./modules/wishlist/wishlist.module').then(m=>m.WishlistModule) },
      { path: 'checkout', loadChildren: ()=> import('./modules/order-checkout/order-checkout.module').then(m=>m.OrderCheckoutModule) }
    ]
  },
  
  { path: '', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule) },  
  { path: 'post', loadChildren: () => import('./modules/ad-create/ad-create.module').then(m => m.AdCreateModule) },
  { path: 'stores', loadChildren: () => import('./modules/stores/stores.module').then(m => m.StoresModule) },
  { path:'', loadChildren:()=>import('./modules/legal-policies/legal-policies.module').then(m=>m.LegalPoliciesModule)},
  { path: 'ad/details', loadChildren: () => import('./modules/ad-details/ad-details.module').then(m => m.AdDetailsModule) },
  { path: 'subscription', loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule) },
  { path: 'receipt', loadChildren: () => import('./modules/receipt/receipt.module').then(m => m.ReceiptModule) },
  

  { path: 'ads', loadChildren: () => import('./modules/ad-list/ad-list.module').then(m => m.AdListModule) },
  

  { path: 'chat', loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule) },
  { path: 'my-account', loadChildren: ()=> import('./modules/my-account/my-account.module').then(m=>m.MyAccountModule) },
  { path: 'success-pay', loadChildren: ()=> import('./modules/subscription-confirm/subscription-confirm.module').then(m=>m.SubscriptionConfirmModule) },
  { path: 'payment-fail', loadChildren: ()=> import('./modules/subscription-failed/subscription-failed.module').then(m=>m.SubscriptionFailedModule) },
  { path: 'subscription-confirmation', loadChildren: ()=> import('./modules/subscription-confirmation/subscription-confirmation.module').then(m=>m.SubscriptionConfirmationModule) },
  { path: 'wish-list', loadChildren: ()=> import('./modules/wishlist/wishlist.module').then(m=>m.WishlistModule) },
  { path: 'checkout', loadChildren: ()=> import('./modules/order-checkout/order-checkout.module').then(m=>m.OrderCheckoutModule) },

  

  

  

  
  { path: '**' , 
    children: [
      {
        path: "", 
        component: PageNotFoundComponent
      }
    ],  
    // component: LayoutComponent 
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
    // preloadingStrategy: PreloadAllModules

})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
