import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private storageKey = 'itemCount';
  constructor() { }
  private itemCount = new BehaviorSubject<any[]>(this.getItemCountFromLocalStorage());
  itemCount$ = this.itemCount.asObservable();


  updateItemCount(newItem: any) {
    if (!newItem || !newItem.adId) {
      console.error('Invalid item data');
      return;
    }

    const currentItemCount = this.itemCount.getValue();
    const existingProduct = currentItemCount.find((obj: any) => obj.adId === newItem.adId);
    if (existingProduct) {
      existingProduct.count = existingProduct.count + 1;
    } else {
      currentItemCount.push({ ...newItem, count: 1 });
    }
    this.itemCount.next(currentItemCount);
    this.saveItemCountToLocalStorage(currentItemCount);
    // console.log(currentItemCount, 'Updated item count');
  }


  deleteItemCount(newItem: any) {
    if (!newItem || !newItem.adId) {
      console.error('Invalid item data');
      return;
    }

    const currentItemCount = this.itemCount.getValue();
    
    const existingProduct = currentItemCount.find((obj: any) => obj.adId === newItem.adId);
    if (existingProduct) {
      // if(existingProduct.count==1){
        this.saveItemCountToLocalStorage(currentItemCount.filter((obj: any) => obj.adId != newItem.adId))
        this.itemCount.next(currentItemCount.filter((obj: any) => obj.adId != newItem.adId));
        // existingProduct.count = existingProduct.count ? existingProduct.count - 1 : 0;
        // this.itemCount.next(currentItemCount);
        // this.saveItemCountToLocalStorage(currentItemCount);
    }else{
      this.itemCount.next(currentItemCount);
      this.saveItemCountToLocalStorage(currentItemCount);
    }
  }

  // Get the current item count
  getItemCount() {
    return this.itemCount.getValue();
  }

  // Get item count from localStorage
  private getItemCountFromLocalStorage(): any[] {
    const savedItemCount = localStorage.getItem(this.storageKey);
    try {
      return savedItemCount ? JSON.parse(savedItemCount) : [];
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
      return [];
    }
  }

  // Save item count to localStorage
  private saveItemCountToLocalStorage(itemCount: any[]) {
    try {
      localStorage.setItem(this.storageKey, JSON.stringify(itemCount));
      // console.log(itemCount, 'Saving item count to localStorage');
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  }

}
