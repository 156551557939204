import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';
import { NodeApiCallService } from 'src/app/services/node-api-call.service';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.scss']
})
export class SearchComponentComponent implements OnInit {
  language = '';
  @Input() appInfo: any;
  @Input() currentUser: any;
  categories: any = [];
  countryList: any = [];
  constructor(
    private _service: GetService,
    private _getCategorieService: NodeApiCallService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.getCountryList();
    this.getCategoriesSubscriber().subscribe(result => {
      this.categories = result.data;
    }, error => {
      console.log(error);
    })
  }


  getCountryList() {
    this._service.commonGetApiNoAuth('app/country-list').subscribe(data => {
      this.countryList = data;
      // console.log(this.countryList)
    }, error => {
      console.log(error);
    })
  }

  getCategoriesSubscriber() {
    return this._getCategorieService.commonGetApiWithoutAuth('app/web/category');
  }

  onCategoryChange(event: any) {
    const selectedCategoryId = event.target.value;
    if (selectedCategoryId) {
      this._router.navigateByUrl(`/ads?categoryId=${selectedCategoryId}`)
    }
  }
}
