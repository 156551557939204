import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppInfo } from '../model/app-info.model';
import { NodeApiData } from '../model/node-api-data.model';
import { NodeApiCallService } from './node-api-call.service';

declare const gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  appLanguage: BehaviorSubject<string>;
  language: string = "EN";
  urlFirstSegment: string = '';
  direction: string = 'ltr';

  private loaderBehavious = new BehaviorSubject(false);
  public isLoader = this.loaderBehavious.asObservable();

  public openAuthModelSubject = new BehaviorSubject("");
  public openAuthModal = this.openAuthModelSubject.asObservable();

  private logginOutBehaviour = new BehaviorSubject(false);
  public isLoggingOut = this.logginOutBehaviour.asObservable();

  private cartCountBehaviour = new BehaviorSubject(0);
  public cartCount = this.cartCountBehaviour.asObservable();
  
  private history: string[] = [];
  constructor(
    private nodeApiCall: NodeApiCallService,
    private toastr: ToastrService,
    private _location: Location,
    private router: Router
  ) { 
    this.appLanguage = new BehaviorSubject('EN');
  }

  setAppLanguage(language: string){
    this.appLanguage.next(language);
    this.language = language;
    if(language === "AR"){
      this.urlFirstSegment = "ar/";
      this.direction = 'rtl';
    }
  }
  getAppInfo(): AppInfo{
    return {
      language: this.language,
      urlFirstSegment: this.urlFirstSegment,
      direction: this.direction
    }
  }
  showLoader(){
    this.loaderBehavious.next(true);
  }
  hideLoader(){
    this.loaderBehavious.next(false);
  }
  showSuccessToast(message: string){
    this.toastr.success('Success!', message, {
      closeButton: true,
      positionClass: 'toast-bottom-center',
    });
  }
  showErrorToast(message: string){
    this.toastr.error(message, 'Sorry!', {
      closeButton: true,
      positionClass: 'toast-bottom-full-width',
      timeOut: 6000
    });
  }

  getLabelText(language: string = "EN") {
    if(language === "AR"){
      return import('../model/lang/ar/label').then(
          data => data.default
        );
    }else{
      return import('../model/lang/en/label').then(
          data => data.default
        );
    }
  }
  getErrorText(language: string = "EN") {
    if(language === "AR"){
      return import('../model/lang/ar/error-msg').then(
          data => data.default
        );
    }else{
      return import('../model/lang/en/error-msg').then(
          data => data.default
        );
    }
  }
  back(referralUrl = ""): void {
    // console.log(this.queryParams.referralUrl)
    if(referralUrl !== ""){
      this.router.navigate([referralUrl])
    }else{
      // this.history.pop()
      // console.log(this.history);
      // if (this.history.length > 0) {
        this._location.back()
      // } else {
      //   this.router.navigateByUrl(this.language === "AR" ? '/ar' : "/");
      // }
    }
  }
  redirectToAuthForUnauthenticatedUser(redirectLink = ""): void{
    this.openAuthModelSubject.next(redirectLink);
  }
  isDoingLogout(action: boolean){
    this.logginOutBehaviour.next(action);
  }

  
 
  //getUserProfile(): Observable<NodeApiData<User[]>>{
  //  return this.nodeApiCall.commonGetApiWithAuth('user-profile');
  //}
}
