import { Component, OnInit } from '@angular/core';
import { AppInfo } from 'src/app/model/app-info.model';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-home-page-layout',
  templateUrl: './home-page-layout.component.html',
  styleUrls: ['./home-page-layout.component.scss']
})
export class HomePageLayoutComponent implements OnInit {
  language = '';
  appInfo: AppInfo;
  category: any;
  lableText: any;
  cloudinaryImageUrl = "";

  currentUser: any;
  isLoggedIn = false;
  constructor(
    private shared: SharedService,
    public authService: AuthService,
  ) {
    if(authService.isBrowserLoad()){
      this.currentUser = authService.getCurrentAuthUser();    
    }
    shared.appLanguage.subscribe(val => {
      this.language = val;
      // this.appInfo.language = val;
    });
    // this.defaultLanguage = this.appInfo.language || 'en';
    this.appInfo = this.shared.getAppInfo();
  }

  ngOnInit(): void {
    this.shared.getLabelText(this.appInfo.language).then(data => {
      this.lableText = data;
    });
  }

}
