<main class="mt-5">
    
    <div class="container mb-11">
        <div class="row">
            <div class="col-md-3 col-sm-3 col-lg-3 only-web-tab"></div>
            <div class="col-md-6 col-lg-6">

                <div class="card">                    
                    <h5 class="card-header receipt-header">Page Not found</h5>
                    <div class="card-body" style="text-align: center;">
                        <h5 class="card-title small-error-text mt-6">
                            <i class="fas fa-exclamation-triangle"></i> &nbsp;Unfortunately the page you are looking is not available or deleted.</h5>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-3 col-lg-3 only-web-tab"></div>
        </div>
    </div>
</main>