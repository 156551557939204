<header>
    <section class="searchbar inner-header">
        <div class="">
            <div class="row w-100">
                <div class="col-xl-2 col-md-6 col-sm-6 mt-sm-1 mt-xs-2 mt-xl-0 mt-md-0">
                    <a routerLink="/" class="navbar-brand">
                        <img src="/assets/images/logo-white.png" alt="">
                    </a>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-6 mt-sm-1 mt-xs-2 mt-xl-0 mt-md-0">
                    <div class="custom-dropdown">
                        <img class="thumbnail-image" src="/assets/images/icons/icons8-location.svg"
                            alt="Select Country" />
                        <select id="countries" class="form-select">
                            <option value="">-Select Country-</option>
                            <option value="{{country.iso2}}" *ngFor="let country of countryList">{{country.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 col-sm-6 mt-sm-1 mt-xs-2 mt-xl-0 mt-md-0">
                    <!-- <div class="col-3  input-group"> -->
                        <div class="input-group">
                            <select class="form-select select_categories" (change)="onCategoryChange($event)">
                                <option value="" selected>Select Categories</option>
                                <option *ngFor="let category of categories" [value]="category.categoryId">{{ category.categoryNameEn }}</option>
                            </select>
                            <input aria-label="" placeholder="Search in All Categories" class="form-control">
                            <button type="button" class="btn btn-primary">
                                <img src="/assets/images/icons/icons8-search.svg" alt="">
                            </button>
                        </div>
                    <!-- </div> -->
                </div>
                <div class="col-xl-3 col-md-6 col-sm-6 mt-sm-1 mt-xs-2 mt-xl-0 mt-md-0">
                    <ul class="list-inline list-unstyled">
                        <li class="list-inline-item">
                            <a href="/wish-list" class="rounded-circle WishlistHeader">
                                <img src="./../../../assets/images/icons/heart.png" alt="aaa">
                            </a>
                        </li>
                        <li class="list-inline-item vmmdl mx-3">
                            <a href="/my-account" class="">
                                <h4 class="mb-0">Account</h4>
                            </a>
                        </li>
                        <li class="list-inline-item vmmdl position-relative">
                            <small class="len">{{cartLength}}</small>
                            <a href="stores/cart" class="">
                                <h4 class="mb-0">
                                    Cart
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                        class="bi bi-cart" viewBox="0 0 16 16">
                                        <path
                                            d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                    </svg>
                                </h4>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</header>

<app-common-dialog (signInDialogueOutput)="signInDialogueInput($event)"></app-common-dialog>
<app-auth></app-auth>