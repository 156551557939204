import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CommonDialogComponent } from './common-component/common/common-dialog.component';
import { AuthButtonLayoutComponent } from './modules/common-modules/auth/auth-button-layout/auth-button-layout.component';
import { AuthComponent } from './modules/common-modules/auth/auth.component';
// import { AuthModule } from './modules/common-modules/auth/auth.module';
import { FooterMenuComponent } from './shop-layout/footer/footer-menu/footer-menu.component';
import { FooterComponent } from './shop-layout/footer/footer.component';

import { HeaderComponent } from './shop-layout/header/header.component';
import { HomepageHeaderComponent } from './shop-layout/header/homepage-header/homepage-header.component';
import { SearchComponentComponent } from './shop-layout/header/search-component/search-component.component';
import { BannerComponent } from './shop-layout/layout/banner/banner.component';

import { HeaderFooterMenuLayoutComponent } from './shop-layout/layout/header-footer-menu-layout/header-footer-menu-layout.component';
import { HomePageLayoutComponent } from './shop-layout/layout/home-page-layout/home-page-layout.component';
import { LayoutComponent } from './shop-layout/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
    declarations: [ LayoutComponent, HeaderComponent, FooterMenuComponent, FooterComponent, HeaderFooterMenuLayoutComponent, HomePageLayoutComponent, SearchComponentComponent, BannerComponent, HomepageHeaderComponent, CommonDialogComponent, AuthComponent, AuthButtonLayoutComponent ],
    imports:[ 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SlickCarouselModule,
        NgOtpInputModule,
        MatSelectModule,
        MatFormFieldModule
        // AuthModule
    ],
    exports:[ LayoutComponent, FooterComponent, BannerComponent, SlickCarouselModule,HeaderComponent ]
})
export class SharedModule { }