import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthComponent } from '../auth.component';

@Component({
  selector: 'app-auth-button-layout',
  templateUrl: './auth-button-layout.component.html',
  styleUrls: ['./auth-button-layout.component.scss']
})
export class AuthButtonLayoutComponent implements OnInit {

  @ViewChild(AuthComponent, {static: true}) 
  private authComponent?: AuthComponent
  constructor() { }

  ngOnInit(): void {
  }

  openLoginComponent(){
    // this.commonDialog.openLoginDialogueModal({});
    if(this.authComponent){
      console.log(this.authComponent)
      this.authComponent?.openLoginModal();
    }
  }
  openSignupComponent(){
    if(this.authComponent)
      this.authComponent.openSignupModal();
  }

}
