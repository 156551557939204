import { isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, 
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
// import { FirebaseApp, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/shared.service';
// import { AngularFireDatabase } from '@angular/fire/database';
import { HelperService } from './services/helper.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Aswaag';
  isLoader: boolean = false;
  isRouteChange: boolean = false;
  // isBrowser: boolean = false;
  currentVersion = 1;
  // version: Observable<any>;
  currentPath = "";
  constructor(
    private router: Router,
    private location: Location,
    private sharedService: SharedService,
    private authService: AuthService,
    private helper: HelperService,
    // private db: AngularFireDatabase,
    // @Inject(PLATFORM_ID) platformId: Object
  ){
    this.currentPath = location.path();
    let url = location.path().split('/');
    if(url[1] === "ar"){
      sharedService.setAppLanguage('AR');
    }else{
      sharedService.setAppLanguage('EN');
    }
    // this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    });
    // this.version = db.object('app').valueChanges();

  }
  ngOnInit(){
    // console.log('app component')
    setTimeout(() => {
      this.sharedService.isLoader.subscribe(status => {
        this.isLoader = status;
      })
    });
    // ######### Check User logged in Info & create anonymous user for new visitor ######
    // console.log(this.authService.isBrowserLoad())
    if(this.authService.isBrowserLoad() && this.authService.loggedInUserType() === ""){
      // console.log('absharalam')
      // console.log(this.authService.isAPIReady())
      // this.authService.createAnonymousUser();
    }
    // ########## End user logged in Info ###############################################
    
    if(this.authService.isBrowserLoad()){
      // this.addGAScript();
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: any) => {
        /** START : Code to Track Page View  */
        // console.log(event)
        //  gtag('event', 'page_view', {
        //     page_path: event.urlAfterRedirects
        //  })
        /** END */
      })

      // this.version.subscribe(v => {
      //   console.log(v.website_version, this.currentVersion)
      //   // alert('version'+ v.angularversion);
      //   if(this.currentVersion != v.website_version){
      //     this.helper.htmlElement('.overlay-full').style.display = "block";
      //   }
      // })
    }
  }
  ngAfterViewInit(){
    
  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isRouteChange = true
    }
    if (event instanceof NavigationEnd) {
      this.isRouteChange = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isRouteChange = false
    }
    if (event instanceof NavigationError) {
      this.isRouteChange = false
    }
  }
}
