import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared.service';
import { HelperService } from '../helper.service';
import { CookieService } from 'ngx-cookie';
// import { CookieService } from '../cookie.service';
// import { CookieService } from 'ngx-cookie';

@Injectable()
export class HeaderRequestInterceptor implements HttpInterceptor {

  constructor(
    private shared: SharedService,
    private helper: HelperService,
    private cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {
    let lang = this.shared.getAppInfo().language === "AR" ? '2' : '1';
    // let authToken = localStorage.getItem('_aai') || "";
     let authToken = this.cookieService.get('_aai') || ""; //aswaag_auth_information

    //let authToken = "";
    //if(this.helper.isPlatformBrowser()){
    //  authToken = localStorage.getItem('_aai') || ""; //aswaag_auth_information
    //}
        
    if(req.headers.get('No-Auth') == 'true'){
        const clonedreq = req.clone({
            setHeaders: {
                "x-app-key": environment.NODE_X_APP_KEY,
                'Content-Type': 'application/json',
                'app-lang': lang,
                'device-type': '4',
              }
        });
        return next.handle(clonedreq);
    }
    if(authToken !== null && authToken !== ""){
        const headers = req.headers
                        .append('app-lang', lang)
                        .append('device-type', '4')
                        .append('X-AUTH-TOKEN', authToken)
                        .append('x-app-key', environment.NODE_X_APP_KEY);
        const reqClone = req.clone({
                headers 
                });
        return next.handle(reqClone);
      
        
    }else{
        return next.handle(req);
    }
  }
}
