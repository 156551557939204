import { Component, OnInit } from '@angular/core';
import { AppInfo } from 'src/app/model/app-info.model';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  language = '';
  appInfo: AppInfo;
  category: any;
  lableText: any;
  cloudinaryImageUrl = "";

  currentUser: any;
  isLoggedIn = false;
  constructor(
    private shared: SharedService,
    private authService: AuthService,
  ) {
    //if(authService.isBrowserLoad()){
      this.currentUser = authService.getCurrentAuthUser();
    //}
    shared.appLanguage.subscribe(val => {
      this.language = val;
      // this.appInfo.language = val;
    });
    // this.defaultLanguage = this.appInfo.language || 'en';
    this.appInfo = this.shared.getAppInfo();
    
  }

  ngOnInit(): void {
    this.shared.getLabelText(this.appInfo.language).then(data => {
      this.lableText = data;
    });
  }

}
