<section class="bnr">
    <div class="mainslider">
        <div class="slick-wrapper">

            <!-- You can now use your library component in app.component.html -->
            <ngx-slick-carousel *ngIf="isBrowserLoad" class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
              (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
              <div ngxSlickItem *ngFor="let slide of bannerAd" class="slide" style="margin: 0;">
                <img src="{{s3Url}}{{slide.bannerImage}}" alt="" width="100%">
              </div>
            </ngx-slick-carousel>
            
        </div>            
     </div>
 </section>
