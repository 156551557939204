import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { CommonDialogComponent } from 'src/app/common-component/common/common-dialog.component';
import { AppInfo } from 'src/app/model/app-info.model';
import { Category } from 'src/app/model/category';
import { AuthComponent } from 'src/app/modules/common-modules/auth/auth.component';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { GetService } from 'src/app/services/get.service';
import { HelperService } from 'src/app/services/helper.service';
import { NodeApiCallService } from 'src/app/services/node-api-call.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // category = new Category({});
  language = '';
  category: any;
  cloudinaryImageUrl = "";
  isBrowserLoad = false;
  labelText: any = {};

  @Input() appInfo: any;
  @Input() currentUser: any;
  searchPlaceholder = "";
  isLoggedIn = false;
  cartCount = 0;
  searchTermForm: FormGroup;
  keywordSuggestionLists: any = [];

  @ViewChild(CommonDialogComponent, { static: true })
  private commonDialog: CommonDialogComponent
  @ViewChild(AuthComponent, { static: true })
  private authComponent?: AuthComponent;
  categories: any = [];
  countryList: any = [];
  cartLength: any;
  constructor(
    private location: Location,
    private router: Router,
    private nodeApi: NodeApiCallService,
    private authService: AuthService,
    public helper: HelperService,
    private shared: SharedService,
    private socialAuthService: SocialAuthService,
    private fb: FormBuilder,
    private _service: GetService,
    private _cartService: CartService
  ) {
    this._cartService.itemCount$.subscribe((res) => {
      this.cartLength = res.length;
    })

    // this.currentUser = authService.getCurrentAuthUser();    
    // shared.appLanguage.subscribe(val => {
    //   this.language = val;
    //   // this.appInfo.language = val;
    // });
    // // this.defaultLanguage = this.appInfo.language || 'en';
    // this.appInfo = this.shared.getAppInfo();
    this.commonDialog = new CommonDialogComponent(helper);
    this.searchTermForm = fb.group({
      term: ['', Validators.required]
    })

  }

  get isLogin() {
    return this.currentUser ? Object.keys(this.currentUser).length !== 0 : false;
  }

  ngOnInit(): void {
    this.shared.getLabelText(this.appInfo?.language).then(data => {
      this.labelText = data;
    })
    if (this.authService.isBrowserLoad()) {
      this.searchPlaceholder = this.appInfo?.language === 'AR' ? "البحث" : "Search";
      if (this.authService.isLoggedIn()) {
        this.isLoggedIn = true;
      }
    }

    this.getCategoriesSubscriber().subscribe(result => {
      this.categories = result.data;
    }, error => {
      console.log(error);
    })
    this.getCountryList();
    this.getCartListsss();
  }

  openLoginComponent() {
    // this.commonDialog.openLoginDialogueModal({});
    console.log(this.location.path())
    if (this.authComponent)
      this.authComponent.openLoginModal(this.location.path());
  }
  openSignupComponent() {
    if (this.authComponent)
      this.authComponent.openSignupModal();
  }
  logout(): void {
    this.authComponent?.doLogout();
  }

  changeLanguage(language: string) {
    // localStorage.setItem('_ln', language);
    let url = this.location.path().split('/');
    // console.log(url);

    if (language === "AR") {
      url[0] = 'ar';
      let newURL = url.join('/');
      // console.log(url, newURL);
      window.location.href = "/" + newURL;
    } else {
      url.splice(0, 2);
      let newURL = url.join('/');
      // console.log(url, newURL);
      window.location.href = "/" + newURL;
    }
  }
  doLogOut() {
    this.authService.doLogout();
    this.socialAuthService.authState.subscribe((user) => {
      // console.log(user);
      if (user !== null) {
        this.socialAuthService.signOut(true)
      }
    });
    // this.shared.isDoingLogout(true);
    // console.log(this.location.path())
    // this.router.navigate(['/app-auth/logout'], {queryParams: {redirect: this.helper.encodeURL(this.location.path())}});
    window.location.href = this.location.path();
  }
  onKeywordChange(event: any, autoSuggestClassName: string = ".auto-suggest") {
    // console.log(event.target.value, autoSuggestClassName);
    if (event.target.value.length >= 2) {
      this.helper.htmlElement(autoSuggestClassName).style.display = "block";
      this.helper.htmlElement('.content').setAttribute('class', 'content custom-overlay-container');
      console.log(this.helper.htmlElement(autoSuggestClassName))
      this.nodeApi.commonPostCallWithoutAuth({ query: event.target.value }, 'autosuggesstions').subscribe((result: any) => {
        console.log(result.data);
        this.keywordSuggestionLists = result.data.suggestionList;
      }, error => {
        console.log(error);
      })
      // this.helper.htmlElement('.main').style.opacity = "0.2";
    } else if (event.target.value === "") {
      this.helper.htmlElement('.content').setAttribute('class', 'content');
      this.helper.htmlElement(autoSuggestClassName).style.display = "none";
      // this.helper.htmlElement('.main').style.opacity = "unset";

    }
  }
  selectKeyword(keyword: any, autoSuggestClassName: string = ".auto-suggest") {
    this.helper.htmlElement(autoSuggestClassName).style.display = "none";
    this.helper.htmlElement('.content').setAttribute('class', 'content');
    // console.log(keyword)
    let postData = {
      query: keyword.keyword,
      searchType: "Y",
      keywordId: keyword.id
    }
    this.navigateToSearch(postData);
  }
  searchFormSubmit(): void {
    console.log(this.searchTermForm.value)
    if (this.searchTermForm.value.term === "") {
      return;
    }
    let postData = {
      query: this.searchTermForm.value.term,
      searchType: "N",
    }
    this.navigateToSearch(postData);
  }

  navigateToSearch(searchParams: any) {
    this.router.navigate(['/' + this.appInfo.urlFirstSegment + 'search'], { queryParams: { q: this.helper.encodeURL(searchParams.query), k: searchParams.searchType } })
  }


  signInDialogueInput(event: any) {

  }

  getCategoriesSubscriber() {
    return this.nodeApi.commonGetApiWithoutAuth('app/web/category');
  };

  getCountryList() {
    this._service.commonGetApiNoAuth('app/country-list').subscribe(data => {
      this.countryList = data;
      // console.log(this.countryList)
    }, error => {
      console.log(error);
    })
  }

  getCartListsss() {
    this.nodeApi.getCartData('app/store/cart').subscribe((res: any) => {
      for (let i of res.body.data) {
        this._cartService.updateItemCount(i.adId);
      }
    })
  }

  onCategoryChange(event: any) {
    const selectedCategoryId = event.target.value;
    if (selectedCategoryId) {
      this.router.navigateByUrl(`/ads?categoryId=${selectedCategoryId}`)
    }
  }

}
