import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import decodeToken from 'jwt-decode';
// import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { CookieService } from './cookie.service';
// import { CookieService } from './cookie.service';
import { HelperService } from './helper.service';
import { NodeApiCallService } from './node-api-call.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isBrowser: boolean = false;
  constructor(
    private nodeApiCall : NodeApiCallService,
    private helper: HelperService,
    @Inject(PLATFORM_ID) platformId: Object,
     private cookieService: CookieService,
    private sharedSerice: SharedService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }
  getCurrentAuthUser(): object {
    try{
      let userInfo = this.cookieService.get('_aai') || "";
      //if(this.isBrowser){
        //let userInfo: string = localStorage.getItem('_aai') || "";
        if(userInfo !== "") {
            return decodeToken(userInfo);
        }
      //}
      return {};      

    }catch(error){
      return {};
    }
  }
  isBrowserLoad(): boolean {
    return this.isBrowser
  }
  isLoggedIn(): boolean {
    let currentUser: any = this.getCurrentAuthUser();
    if(Object.entries(currentUser).length > 0){
      if(currentUser.userType === "REGISTERED"){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  loggedInUserType(): string {
    let currentUser: any = this.getCurrentAuthUser();
    // console.log(currentUser);
    if(Object.entries(currentUser).length > 0){
      if(currentUser.userType === "REGISTERED"){
        return 'REGISTERED';
      }else if(currentUser.userType === "GUEST"){
        return "GUEST";
      }else if(currentUser.userType === "RANDOM"){
        return "RANDOM";
      }else {
        return "";
      }
    }else{
      return "";
    }
  }
  doLogout(){
    let anonymousUserToken = localStorage.getItem('_anonymous') || "";
    // console.log(anonymousUserToken)
    if(anonymousUserToken !== ""){
      // this.cookieService.put('_aai', anonymousUserToken);
      localStorage.setItem('_aai', anonymousUserToken);
    }
    this.sharedSerice.isDoingLogout(true);
  }
  createAnonymousUser(){
    return this.nodeApiCall.commonPostCallWithoutAuth({}, 'anonymous-user')
    .subscribe((result: any)  => {
      localStorage.setItem('_anonymous', result.data.tokens); 
      // this.cookieService.put('_aai', result.data.tokens);
      localStorage.setItem('_aai', result.data.tokens);
    },
    (error) =>{
        console.log("Something wrong:-", error)
    }
    );
  }
  decodeAnonymousUserToken(): object{
    let token = localStorage.getItem('_anonymous') || "";

    if(token !== ""){
      return this.helper.decodeToken(token);
    }
    return {};
  }
  generateUID(){
    return this.nodeApiCall.commonPostCallWithoutAuth({}, 'guid')
    .subscribe((result: any)  => {
      // console.log(result.data)
      localStorage.setItem('_K_GUID', result.data.uid); 
    },
    (error) =>{
        console.log("Something wrong:-", error)
    }
    );
  }
  getUID(): string{
    let guid: string = localStorage.getItem('_K_GUID') || "";
    try{
      if(guid !== "") {
          return 'K-'+guid;
      }
      return "";  

    }catch(error){
      return "";
    }
  }
  grefreshAuthToken() {
    return new Observable<any>(observer => {
      this.nodeApiCall.commonGetApiWithAuth('refresh-auth-token').subscribe(result => {
        // console.log(result)
        // this.cookieService.put('_aai', result.tokens);
        localStorage.setItem('_aai', result.tokens);
        observer.next(result.tokens);
        observer.complete();
      }, error => {
        observer.error(error)
        observer.complete();
      })
    })
  }
}
