import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
//import { CookieService } from 'src/app/services/cookie.service';
import { GetService } from 'src/app/services/get.service';
import { HelperService } from 'src/app/services/helper.service';
import { NodeApiCallService } from 'src/app/services/node-api-call.service';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import CustomValidator from 'src/app/services/_validators/custom.validator';
import { SharedService } from 'src/app/services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy, AfterViewInit {

  countryList: any = [];
  isPlatformBrowser: boolean = false;
  signupForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    userName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    role: ['CUSTOMER', Validators.required],
    mobile: ['', Validators.required],
    country: ['', Validators.required]
  }, {
    validators: [CustomValidator.match('password', 'confirmPassword')]
  });
  isSignupFormSubmit: boolean = false;
  isSignupFormDisable: boolean = false;

  loginForm: FormGroup = this.fb.group({
    userName: ['', Validators.required],
    password: ['', Validators.required]
  });
  isLoginFormSubmit: boolean = false;
  isLoginFormProcessing: boolean = false;
  isOTPVerificationProcessing: boolean = false;

  forgotPasswordForm: FormGroup = this.fb.group({
    userName: ['', Validators.required],
  });
  isForgotPasswordFormSubmit: boolean = false;
  isForgotPasswordFormProcessing: boolean = false;
  isPasswordSentToEmail: boolean = false;

  errorMessage = {
    loginError: "",
    signupError: "",
    otpError: "",
    forgotPasswordError: ""
  };
  queryParams: any = {
    referralUrl: null,
    flow: null
  }
  recaptchaVerifier: RecaptchaVerifier | null = null;
  otpConfirmationResult: any;
  private history: string[] = [];
  otpConfig = {length:6};
  otp: string = "";
  authModalUnsubscribe!: Subscription;

  constructor(
    public helper: HelperService,
    private getService: GetService,
    private nodeApiCall: NodeApiCallService,
    private cookieService: CookieService,
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute, 
    private _location: Location,
    private fireAuth: AngularFireAuth,
    private shared: SharedService,
  ) { 
    activeRoute.queryParams.subscribe(val => {
      // this.queryParams.internalSource = val.isrc ? helper.decodeURL(val.isrc) : null;
      this.queryParams.referralUrl = val.redirect ? helper.decodeURL(val.redirect) : null;
      this.queryParams.flow = val.flow ? val.flow : null
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  ngOnInit(): void {
    if(this.helper.isPlatformBrowser()){
      this.isPlatformBrowser = true;
      this.getCountryList();
    }
    this.authModalUnsubscribe = this.shared.openAuthModal.subscribe((data) => {
      // console.log(data)
      if(data !== ""){
        this.openLoginModal(data);
        console.log("again")
      }
    })
  }
  ngAfterViewInit(): void {
    
  }
  ngOnDestroy(): void {
    //console.log("absh")
    this.shared.openAuthModelSubject.next("");
    this.authModalUnsubscribe.unsubscribe();
  }
  get signupFormControl(): { [key: string]: AbstractControl } {
    return this.signupForm.controls;
  }
  get loginFormControl(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
  get forgotPasswordFormControl(): { [key: string]: AbstractControl } {
    return this.forgotPasswordForm.controls;
  }
  openLoginModal(redirectUrl = ""){
    if(redirectUrl !== ""){
      this.queryParams.referralUrl = redirectUrl;
    }
    this.helper.htmlElement('#login_trigger_btn').click();
    //this.authModalUnsubscribe.unsubscribe();
    //console.log(this.authModalUnsubscribe)
  }
  openSignupModal(){
    // if(this.helper.htmlElement('#close-login-modal-btn')){
    //   this.helper.htmlElement('#close-login-modal-btn').click();
    // }
    this.helper.htmlElement('#signup_trigger_btn').click();
    //this.helper.htmlElement('#otp_trigger_btn').click();
    const auth = getAuth();
    // console.log(auth);
    this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response: any) => {
        console.log("Recaptcha Response: ", response);
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        
      }
    }, auth);
  }
  openForgotPasswordModal(){
    this.helper.htmlElement('#forgot_password_trigger_btn').click();
  }
  doSignup(): void{
    this.isSignupFormSubmit = true;
    this.isSignupFormDisable = true;
    console.log(this.signupForm.value)
    if(this.signupForm.invalid){
      this.isSignupFormDisable = false;
      return;
    }

    this.nodeApiCall.commonPostCallWithoutAuth(this.signupForm.value, "auth/signup").subscribe((data: any) => {
      console.log(data)
      this.helper.htmlElement('.close-signup-modal').click();
      this.helper.htmlElement('#otp_trigger_btn').click();
      this.cookieService.put('_arai', data.data.tokens); // _aai = Aswaag register Auth Info
      //localStorage.setItem('_arai', data.data.tokens); // _kai = Aswaag Auth Info
      
      if(this.recaptchaVerifier !== null){
        let countryCode = "+965";
        if(this.signupForm.value.country === "AE"){
          
        }else if(this.signupForm.value.country === "SA"){
        }else if(this.signupForm.value.country === "OM"){
        }else if(this.signupForm.value.country === "QA"){
        }else if(this.signupForm.value.country === "BH"){

        }
        this.fireAuth.signInWithPhoneNumber(countryCode + this.signupForm.value.mobile, this.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log(confirmationResult);
          this.otpConfirmationResult = confirmationResult;
        }).catch((error) => {
          // Error; SMS not sent
          // ...
          console.log("SMS sending error: ", error);
        });
      }else{
        console.log("Recaptcha Verifier: ", this.recaptchaVerifier);
      }
      //this.back();
    }, error => {
      this.isSignupFormDisable = false;
      if(error.error_message !== undefined){
        if(error.error_message === "Validation error"){
          this.errorMessage.signupError = "This email is already registered.";
        }else{
          this.errorMessage.signupError = error.error_message;
        }
      }else{
        this.errorMessage.signupError = "Sorry! somthing went wrong. Please try again.";
      }
      console.log(error);
    })
  }


  
  onOtpChange(event: any): void {
    this.otp = event;
  }
  verifyOTP(): void{
    try {
      //this.isOTPVerificationProcessing = true;return;
      if(this.otp.length === 6){
        this.isOTPVerificationProcessing = true;
        this.nodeApiCall.otpVerifyfication(this.otp).subscribe((res:any)=>{
          if(res?.status=="success"){
            window.location.reload()
          }
        })
        // this.nodeApiCall.otpVerifyfication(this.otp).then((response: any) => {
        //   console.log("OTP verification response: ", response);
        //   this.helper.htmlElement('.close-signup-modal').click();
        //   this.helper.htmlElement('#otp_trigger_btn').click();
        //   let tokens = this.cookieService.get('_arai'); // get tokens when got during signup
        //   this.cookieService.put('_aai', tokens || ""); // _aai = Aswaag Auth Info

        //   this.nodeApiCall.commonPostCallWithAuth({}, "auth/verify-mobile").subscribe(result => {
        //     this.nodeApiCall.commonPostCallWithAuth({}, 'auth/refresh-auth-token').subscribe((data: any) => {
        //       this.cookieService.put('_aai', data.data.tokens);
        //       this.cookieService.remove('_arai');
        //     }, error =>{
        //       this.cookieService.remove('_aai');
        //     })
        //   }, error => {
        //     this.cookieService.remove('_aai');
        //   })
        //   localStorage.setItem('_aai', tokens || "" ); // _aai = Aswaag Auth Info
        //   this.back();
        // }).catch((err: any) => {
        //   this.isOTPVerificationProcessing = false;
        //   console.log('OTP verification error: ', err);
        //   this.errorMessage.otpError = "Invalid OTP";
        // });
      }else{
        this.errorMessage.otpError = "Please Enter OTP";
      }
    } catch (error: any) {
      //console.log(error)
      this.isOTPVerificationProcessing = false;
      this.errorMessage.otpError = "Sorry! there is something technical error.";
    }
  }
  doLogin(): void{
    // console.log(this.loginForm.value)
    this.isLoginFormSubmit = true;
    if(this.loginForm.invalid){
      return;
    }

    this.isLoginFormProcessing = true;

    let postData = {
      userName: this.loginForm.value.userName,
      password: this.loginForm.value.password
    }
    this.nodeApiCall.commonPostCallWithoutAuth(postData, 'auth/login')
    .subscribe((result: any)  => {
          this.cookieService.put('_aai', result.data.tokens); // _aai = Aswaag Auth Info
          //localStorage.setItem('_aai', result.data.tokens); // _aai = Aswaag Auth Info
          this.back();
      },
      (error) =>{
        this.isLoginFormProcessing = false;
        console.log("Something wrong:-", error)
        if(error.error_message !== undefined){
          if(error.error_message === "USERNAME_NOT_EXIST" || error.error_message === "PASSWORD_WRONG"){
            this.errorMessage.loginError = "Incorrect email or password, please try again";
          }else{
            this.errorMessage.loginError = error.error_message;
          }
        }else{
          this.errorMessage.loginError = "Sorry! somthing went wrong. Please try again.";
        }
      });
  }
  doForgotPassword(): void{
    this.isForgotPasswordFormSubmit = true;
    this.isForgotPasswordFormProcessing = true;
    if(this.forgotPasswordForm.invalid){
      this.isForgotPasswordFormProcessing = false;
      return;
    }

    this.nodeApiCall.commonPostCallWithoutAuth(this.forgotPasswordForm.value, "auth/forgot-password").subscribe((data: any) => {
      console.log(data);
      this.isPasswordSentToEmail = true;
      this.isForgotPasswordFormProcessing = false;
    }, error => {
      this.isForgotPasswordFormProcessing = false;
      if(error.error_message !== undefined){
          this.errorMessage.forgotPasswordError = error.error_message;
      }else{
        this.errorMessage.forgotPasswordError = "Sorry! somthing went wrong. Please try again.";
      }
      console.log(error);
    })
  }
  doLogout(redirectUrl = ""): void{
    if(redirectUrl !== ""){
      this.queryParams.referralUrl = redirectUrl;
    }
    this.cookieService.remove('_aai');
    this.back();
  }

  getCountryList(){
    this.getService.commonGetApiNoAuth('app/country-list').subscribe(data => {
      this.countryList = data;
      // console.log(this.countryList)
    }, error => {
      console.log(error);
    })
  }

  back(): void {
    // console.log(this.queryParams.referralUrl)
    if(this.queryParams.referralUrl !== null){
      // this.router.navigate([this.queryParams.referralUrl])
      window.location.href = this.queryParams.referralUrl;
    }else{
      this.history.pop()
      if (this.history.length > 0) {
        // console.log(this._location.back());
        this._location.back()
      } else {
        // this.router.navigateByUrl(this.appInfo.language === "AR" ? '/ar' : "/");
        window.location.href =  "/";
      }
    }
  }

}
