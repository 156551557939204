import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

// export function mustBeInt(control : AbstractControl){
//     if(control.value < 1){
//         return {mustBeInt: true};
//     }
//     return null;
// }
// export function greaterToZero(control : AbstractControl){
//   if(control.value <= 0){
//       return {greaterToZero: true};
//   }
//   return null;
// }

export default class CustomValidator {
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl !== null && checkControl.errors && !checkControl.errors.matching) {
        return null;
      }

      if (control !== null && checkControl !== null && control.value !== checkControl.value) {
        checkControl.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }
}