import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
    .pipe(
      // retry(0),
      catchError((error: HttpErrorResponse) => {
          let errorMessage = {};
          if (error.error instanceof HttpErrorResponse) {
              // client-side error
              errorMessage = {error_type:'CLIENT_SIDE', error_message: error.error.message, data: null};
          } else {
            // server-side error
            // console.log(error);
            // console.log(error.message);
            // console.log(error.name);
          
            let error_msg = "";
            if(error.error.errorMessage != undefined)
                error_msg = error.error.errorMessage;
            if(error.error.message != undefined)
                error_msg = error.error.message;

            errorMessage = {error_type: 'SERVER_SIDE', error_code: error.status, error_message: error_msg, error_name: error.name, data: error.error.data};

          }
          

          return throwError(errorMessage);
          // window.alert(errorMessage);
      })
    )
  }
}
