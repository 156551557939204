import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetService {

  constructor(
    private http: HttpClient
  ) { }

  commonGetApiWithAuth(apiName: string, body:any = {}, queryParam:any = {}){
    return new Observable<any>(observer => {
      let headersRequest = new HttpHeaders({
        'No-Auth': 'false',        
      })
      let url = environment.NODE_APP_API+apiName;
      if(body){
        url += '/'+ Object.keys(body).map(key => body[key]).join('/'); 
      }
      if(queryParam)
        url += '?'+ Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&'); 

      this.http.get(url, {headers: headersRequest, observe: 'response'})
        .subscribe((snapshot: HttpResponse<any>) => {
          // console.log(snapshot)
          // console.log(snapshot.headers);
            snapshot.body.data.headers = snapshot.headers;
            observer.next(snapshot.body.data);
            observer.complete();
        },(error) => {
          observer.error(error)
          observer.complete();
        })
    })
  }
  commonGetApiNoAuth(apiName: string, body:any = {}, queryParam:any = {}){
    return new Observable<any>(observer => {
      let headersRequest = new HttpHeaders({
        'No-Auth': 'true',        
      })
      let url = environment.NODE_APP_API+apiName;
      if(body){
        url += '/'+ Object.keys(body).map(key => body[key]).join('/'); 
      }
      if(queryParam)
        url += '?'+ Object.keys(queryParam).map(key => key + '=' + queryParam[key]).join('&'); 

      this.http.get(url, {headers: headersRequest, observe: 'response'})
        .subscribe((snapshot: HttpResponse<any>) => {
          // console.log(snapshot)
          // console.log(snapshot.headers);
            snapshot.body.data.headers = snapshot.headers;
            observer.next(snapshot.body.data);
            observer.complete();
        },(error) => {
          observer.error(error)
          observer.complete();
        })
    })
  }
}
