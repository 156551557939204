<section class="searchbar">
    <div class="container">
        <div class="row justify-content-center pt-5">
            <div class="col-md-12 col-12">
                <div class="row align-items-center">
                    <!-- Country Select -->
                    <div class="col-lg-3 col-md-4 col-12 mb-3 mb-md-0">
                        <div class="custom-dropdown">
                            <img class="thumbnail-image" src="/assets/images/icons/icons8-location.svg"
                                alt="Select Country" />
                            <select id="countries" class="form-select">
                                <option value="">-Select Country-</option>
                                <option value="{{country.iso2}}" *ngFor="let country of countryList">{{country.name}}</option>
                            </select>
                            <!-- <select class="form-control form-select">
                                <option value="">-Select Country-</option>
                                <option value="{{country.iso2}}" *ngFor="let country of countryList">{{country.name}}</option>
                            </select> -->
                        </div>
                    </div>

                    <!-- Categories and Search -->
                    <div class="col-lg-6 col-md-8 col-12 mb-3 mb-md-0">
                        <div class="input-group">
                            <select class="form-select select_categories" (change)="onCategoryChange($event)">
                                <option value="" selected>Select Categories</option>
                                <option *ngFor="let category of categories" [value]="category.categoryId">{{ category.categoryNameEn }}</option>
                            </select>
                            <input aria-label="" placeholder="Search in All Categories" class="form-control">
                            <button type="button" class="btn btn-primary">
                                <img src="/assets/images/icons/icons8-search.svg" alt="">
                            </button>
                        </div>
                    </div>

                    <!-- Post Your Ad Button -->
                    <div class="col-lg-3 col-md-12 col-12 text-center text-md-end">
                        <a type="button" class="btn btn-warning w-100 w-md-auto" routerLink="/post/create">
                            <img src="/assets/images/icons/icons8-camera.svg" alt="">Post Your Ad
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>