<div id="overlay" *ngIf="isLoader">
    <div class="cv-spinner">
        <span class="spinner">
        </span>
    </div>
</div>
<div class="overlay-full">
    <div style="text-align: center;">
        <div style="color: #fff; font-weight: bold; margin-top: 224px;">
            New major updaes is available for this website. Please refresh or click Refresh button.
        </div>
        <div>
            <a href="{{currentPath}}" class="btn btn-kupon">Refresh</a>
        </div>
    </div>
</div>
<div class="progress-bar" *ngIf="isRouteChange">
    <span class="bar">
      <span class="progress"></span>
    </span>
</div>
<router-outlet></router-outlet>
