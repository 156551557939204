import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  commonDialogueModalData = {
    title: "",
    bodyMessage: "",
    bodyWarningMessage: "",
    bodyErrorMessage: "",
    modalType: "DIALOGUE", //DIALOGUE, CONFIRMATION
    okButtonName: "",
    okActionResponse: "SUCCESS",
    dismissButtonName: "Close",
    // isShowDismissButton: false,
    dismissActionResponse: "FAIL",
    modalName: "",
    isShowCloseTopCorner: false,
    extraObject: {}
  }

  loginDialogueModalData: any = {

  }


  @Output() commonDialogueModalDataOutput = new EventEmitter<object>();
  @Output() signInDialogueOutput = new EventEmitter<object>();
  constructor(
    private helper: HelperService
  ) { }

  ngOnInit(): void {
  }
  test(){
    console.log('test')
  }
  openExpiredCartModal(){
    // console.log(3333)
    // console.log(this.helper.htmlElement('#expired-cart-modal-trigger-btn'))
    // this.helper.htmlElement('#expired-cart-modal-trigger-btn').click();
    this.helper.htmlElement('#common_dialogue_modal_trigger_btn').click();
  }
  openCommonDialogueModal(params: any){
    if(params.bodyWarningMessage !== undefined && params.bodyWarningMessage !== null){
      this.commonDialogueModalData.bodyWarningMessage = params.bodyWarningMessage;
    }
    if(params.bodyErrorMessage !== undefined && params.bodyErrorMessage !== null){
      this.commonDialogueModalData.bodyErrorMessage = params.bodyErrorMessage;
    }
    if(params.bodyMessage !== undefined && params.bodyMessage !== null){
      this.commonDialogueModalData.bodyMessage = params.bodyMessage;
    }
    if(params.title !== undefined && params.title !== null){
      this.commonDialogueModalData.title = params.title;
    }
    if(params.modalType !== undefined && params.modalType !== null){
      this.commonDialogueModalData.modalType = params.modalType;
    }
    if(params.okButtonName !== undefined && params.okButtonName !== null){
      this.commonDialogueModalData.okButtonName = params.okButtonName;
    }
    if(params.okButtonName !== undefined && params.okButtonName !== null){
      this.commonDialogueModalData.okButtonName = params.okButtonName;
    }
    if(params.okActionResponse !== undefined && params.okActionResponse !== null){
      this.commonDialogueModalData.okActionResponse = params.okActionResponse;
    }
    if(params.dismissButtonName !== undefined && params.dismissButtonName !== null){
      this.commonDialogueModalData.dismissButtonName = params.dismissButtonName;
    }
    if(params.dismissActionResponse !== undefined && params.dismissActionResponse !== null){
      this.commonDialogueModalData.dismissActionResponse = params.dismissActionResponse;
    }
    if(params.modalName !== undefined && params.modalName !== null){
      this.commonDialogueModalData.modalName = params.modalName;
    }
    if(params.isShowCloseTopCorner !== undefined && params.isShowCloseTopCorner !== null){
      this.commonDialogueModalData.isShowCloseTopCorner = params.isShowCloseTopCorner;
    }
    if(params.extraObject !== undefined && params.extraObject !== null){
      this.commonDialogueModalData.extraObject = params.extraObject;
    }
    this.helper.htmlElement('#common_dialogue_modal_trigger_btn').click();

  }
  closeCommonDialogueModal(){
    this.helper.htmlElement('.close-common-dialogue-modal').click();
  }
  okButtonClickAction(){
    let finalResp = {...this.commonDialogueModalData, ...{clickedButton: "OK"}};
    this.commonDialogueModalDataOutput.emit(finalResp)
  }
  dismissButtonClickAction(){
    // console.log("dismissal")
    let finalResp = {...this.commonDialogueModalData, ...{clickedButton: "DIMISSAL"}};
    this.commonDialogueModalDataOutput.emit(finalResp)
  }


  openLoginDialogueModal(params: any){    
    this.helper.htmlElement('#login_dialogue_modal_trigger_btn').click();

  }
}
